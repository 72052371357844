const SideContent = function() {

    var _doc = document;
    var _body = _doc.querySelector('body');
    var _triggers = _doc.querySelectorAll('[data-trigger-sc]');
    var _closeTrigger = _doc.querySelector('.m-close-content');
    var _closeSections = _doc.querySelectorAll('[data-close-section]');
    var _activeContent;

    function _lockBody() {
        _closeTrigger.classList.add('is-active');
        _body.classList.add('is-lock');
    }

    function _close() {
        _body.classList.remove('is-lock');
        window.location.hash = '#!';
        _closeTrigger.classList.remove('is-active');
        _activeContent = _doc.querySelectorAll('.m-side-content.is-active');
        Array.prototype.forEach.call(_activeContent, function(el, i){
            el.classList.remove('is-active');
        });
    }

    function _listenerSideContent() {
        _closeTrigger.addEventListener('click', function(e) {
            e.preventDefault();
            _close();
        });

        Array.prototype.forEach.call(_closeSections, function(el, i){
            el.addEventListener('click', function(e) {
                e.preventDefault();
                _close();
            });
        });
    }

    function _init() {
        _listenerSideContent();
    }

    return {
        lockBody: _lockBody,
        close: _close,
        init: _init
    }

}

export default SideContent
