const SmoothScroll = function() {

    var $window = window;
    var _body = document.querySelector('body');
    var scrollTime = 1.2;
    var scrollDistance = 170;

    function _init() {
        if ($window.addEventListener) {
            $window.addEventListener("mousewheel", function(event) {
                if (!_body.classList.contains('is-lock')) {
                    event.preventDefault();
                    var delta = event.wheelDelta/120 || -event.detail/3;
                    var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
                    var finalScroll = scrollTop - parseInt(delta*scrollDistance);
                    TweenLite.to($window, scrollTime, {
                        scrollTo: {
                            y: finalScroll,
                            autoKill: true
                        },
                        ease: Power1.easeOut,
                        overwrite: 5
                    });
                }
            }, false);
        }
    }

    return {
        init: _init
    }

}

export default SmoothScroll

