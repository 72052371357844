/*! ScrollMagic v2.0.5 | (c) 2015 Jan Paepke (@janpaepke) | license & info: http://scrollmagic.io */
!function(e,n){"function"==typeof define&&define.amd?define(["ScrollMagic","TweenMax","TimelineMax"],n):"object"==typeof exports?(require("gsap"),n(require("scrollmagic"),TweenMax,TimelineMax)):n(e.ScrollMagic||e.jQuery&&e.jQuery.ScrollMagic,e.TweenMax||e.TweenLite,e.TimelineMax||e.TimelineLite)}(this,function(e,n,r){"use strict";e.Scene.addOption("tweenChanges",!1,function(e){return!!e}),e.Scene.extend(function(){var e,t=this;t.on("progress.plugin_gsap",function(){i()}),t.on("destroy.plugin_gsap",function(e){t.removeTween(e.reset)});var i=function(){if(e){var n=t.progress(),r=t.state();e.repeat&&-1===e.repeat()?"DURING"===r&&e.paused()?e.play():"DURING"===r||e.paused()||e.pause():n!=e.progress()&&(0===t.duration()?n>0?e.play():e.reverse():t.tweenChanges()&&e.tweenTo?e.tweenTo(n*e.duration()):e.progress(n).pause())}};t.setTween=function(o,a,s){var u;arguments.length>1&&(arguments.length<3&&(s=a,a=1),o=n.to(o,a,s));try{u=r?new r({smoothChildTiming:!0}).add(o):o,u.pause()}catch(e){return t}return e&&t.removeTween(),e=u,o.repeat&&-1===o.repeat()&&(e.repeat(-1),e.yoyo(o.yoyo())),i(),t},t.removeTween=function(n){return e&&(n&&e.progress(0).pause(),e.kill(),e=void 0),t}})});

import SplitText from './_splittext';
import ScrollMagic from "scrollmagic";
require('../_vendors/gsap/MorphSVGPlugin.js');

const Animations = function() {

    var airPlanePath1 = MorphSVGPlugin.pathDataToBezier("#airplane-path-1", {x: 20});
    TweenMax.set("#airplane-1", {yPercent:-100});
    TweenMax.to("#airplane-1", 8, {
        repeat: -1,
        bezier: {
            type: "cubic",
            autoRotate: 50,
            values: airPlanePath1
        }
    });

    var airPlanePath2 = MorphSVGPlugin.pathDataToBezier("#airplane-path-servizi", {x: 20});
    TweenMax.set("#airplane-servizi", {yPercent:-100});
    TweenMax.to("#airplane-servizi", 8, {
        repeat: -1,
        bezier: {
            type: "cubic",
            autoRotate: 50,
            values: airPlanePath2
        }
    });

    var airPlanePathContacts = MorphSVGPlugin.pathDataToBezier("#airplane-path-contacts", {x: 20});
    TweenMax.set("#airplane-contacts", {xPercent:90,yPercent:-10});
    TweenMax.to("#airplane-contacts", 8, {
        repeat: -1,
        bezier: {
            type: "cubic",
            autoRotate: 50,
            values: airPlanePathContacts
        }
    });


    function _parallax() {

        var parallaxCtrl = new ScrollMagic.Controller();
        var parallaxElements = document.querySelectorAll('.a-parallax');

        Array.prototype.forEach.call(parallaxElements, function(el, i){

            TweenMax.set(el, {
                y: '50%',
                opacity: .3
            });

            new ScrollMagic.Scene({
                triggerElement: el,
                duration: window.innerHeight,
                triggerHook: 'onEnter'
            })
            .setTween(el, {
                opacity: 1,
                y: '0%'
            })
            .addTo(parallaxCtrl)

        });

    }

    function _parallaxBg() {

        var parallaxBGCtrl = new ScrollMagic.Controller();
        var parallaxElBG = document.querySelector('.a-parallax-bg');

        new ScrollMagic.Scene({
            triggerElement: parallaxElBG,
            duration: parallaxElBG.offsetHeight,
            triggerHook: 'onLeave'
        })
        .setTween(parallaxElBG, {
            y: '20%'
        })
        .addTo(parallaxBGCtrl);

    }

    function _showTitle() {

        var titleCtrl = new ScrollMagic.Controller();
        var titleTl = [];
        var titleElements = document.querySelectorAll('.a-title');

        Array.prototype.forEach.call(titleElements, function(el, i){
            titleTl[i] = new TimelineLite({ paused: false });
            titleTl[i].to(el, .8, {
                y: 0,
                opacity: 1,
                delay: .5,
                ease: Expo.easeOut
            })
            titleTl[i].to(el.querySelector('span'), .8, {
                y: 0,
                ease: Expo.easeOut
            }, "-=.5");

            new ScrollMagic.Scene({
                triggerElement: titleElements[i],
                triggerHook: 'onEnter'
            })
            .setTween(titleTl[i])
            .addTo(titleCtrl);

        });

    }

    function _init() {
        _parallaxBg();
        _parallax();
        _showTitle();

        var _mySplitText = new SplitText('.m-slider__text span', {type: "lines"});
    }

    return {
        init: _init
    }

}

export default Animations

