import ScrollMagic from "scrollmagic";
import SideContent from './_sideContent.js';

require('../../../../node_modules/gsap/ScrollToPlugin.js');

const SIDECONTENT = new SideContent;

const SideNav = function() {

    var _doc = document;
    var _sections = _doc.querySelectorAll('[data-section]');
    var _sideNav = _doc.querySelector('.m-sidenav');
    var _triggers = _doc.querySelectorAll('[data-scrollto]');

    const getScrollTop = () => {
        if (typeof pageYOffset!= 'undefined'){
            return pageYOffset;
        } else{
            var B= document.body;
            var D= document.documentElement;
            D= (D.clientHeight)? D: B;
            return D.scrollTop;
        }
    }

    const getOffsetTop = elem => {
        var offsetTop = 0;
        do {
            if(!isNaN( elem.offsetTop)) {
                offsetTop += elem.offsetTop;
            }
        }while(elem=elem.offsetParent);
        return offsetTop;
    }

    const isInTheMiddle = (el) => {
        let st = getScrollTop(),
            ot = getOffsetTop(el),
            halfWin = window.innerHeight/2;
        return st > (ot - halfWin) && ((ot + el.offsetHeight - halfWin) >= st)
    }

    function _scrollTo() {
        Array.prototype.forEach.call(_triggers, function(el, i){
            el.addEventListener('click', function(e) {
                e.preventDefault();
                SIDECONTENT.close();
                TweenLite.to(window, 1.5, {
                    scrollTo:{
                        y: this.getAttribute('href'),
                        offsetY: 50
                    },
                    ease: Expo.easeInOut
                });
            })
        });
    }

    function _listenerSidenav() {
        window.onscroll = function() {
            Array.prototype.forEach.call(_sections, function(el, i){
                var attr = el.getAttribute('data-section');
                 if (isInTheMiddle(el)) {
                    _doc.querySelector('[data-id='+attr+']').classList.add('is-active');
                } else {
                    _doc.querySelector('[data-id='+attr+']').classList.remove('is-active');
                }
            });
        };
    }

    function _init() {
        _scrollTo();
        _listenerSidenav();
    }

    return {
        init: _init
    }

}

export default SideNav

