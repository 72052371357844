import Swiper from 'swiper';
import TweenLite from 'gsap';
import Preloader from 'preloader.js';

import isTouch from './_helpers/_h-isTouch.js';

import SideNav from './_modules/_sidenav.js';
import SideContent from './_modules/_sideContent.js';
import SmoothScroll from './_modules/_smoothScroll.js';
import Animations from './_modules/_animations.js';
import News from './_modules/_news.js';
import Services from './_modules/_services.js';

const NEWS = News();
const SERVICES = Services();
const SIDENAV = SideNav();
const SMOOTHSCROLL = SmoothScroll();
const ANIMATIONS = Animations();
const SIDECONTENT = SideContent();

if (isTouch) {
    document.body.classList.remove('no-touch');
    document.body.classList.add('is-touch');
}

window.onload = function() {

    var _images = document.querySelectorAll('[data-preload]');
    var _resources = [];

    Array.prototype.forEach.call(_images, function(img, i){
        _resources.push(img.getAttribute('src'));
    });

    var preloader = new Preloader({
        resources: _resources,
        concurrency: 2
    });

    SMOOTHSCROLL.init();
    SIDECONTENT.init();
    SERVICES.init();
    SIDENAV.init();
    NEWS.init();

    preloader.addCompletionListener(() => {

        ANIMATIONS.init();

        setTimeout(() => {
            TweenLite.to('.m-loader-wrapper', 1.5, {
                opacity: 0,
                scale: 1.1,
                ease: Quint.easeInOut,
                onComplete: () => {
                    TweenLite.set('.m-loader-wrapper', {
                        display: 'none'
                    });

                    // Init Swiper
                    var mySwiper = new Swiper ('.m-slider .swiper-container', {
                        slidesPerView: 1,
                        slideToClickedSlide: true,
                        pagination: '.m-slider .swiper-pagination',
                        paginationClickable: true
                    });

                    // Init Swiper
                    var myMiniSwiper = new Swiper ('.m-mini-slider .swiper-container', {
                        slidesPerView: 1,
                        nextButton: '.m-mini-slider .swiper-button-next',
                        prevButton: '.m-mini-slider .swiper-button-prev',
                    });
                }
            });
        }, 1500)

    });

    preloader.start();

}
