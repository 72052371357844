import SideContent from './_sideContent.js';

const SIDECONTENT = new SideContent;

const Services = function() {

    var _doc = document;
    var _body = _doc.querySelector('body');
    var _triggers = _doc.querySelectorAll('[data-trigger-sc]');
    var _activeContent;

    function _listenerServicesContent() {

        Array.prototype.forEach.call(_triggers, function(el, i){
            el.addEventListener('click', function() {

                SIDECONTENT.lockBody();
                _activeContent = _doc.querySelectorAll('.m-side-content.is-active');
                Array.prototype.forEach.call(_activeContent, function(el, i){
                    el.classList.remove('is-active');
                });

                var _id = this.getAttribute('href');
                _doc.querySelector(_id).classList.add('is-active');

            });
        });

    }

    function _init() {
        _listenerServicesContent();

        var _sideContentIds = [];
        Array.prototype.forEach.call(_doc.querySelectorAll('[data-side-content]'), function(el, i){
            _sideContentIds.push(el.getAttribute('id'));
        });

        if (window.location.hash) {
            if (_sideContentIds.indexOf(window.location.hash.substring(1)) != -1) {
                _doc.querySelector(window.location.hash).classList.add('is-active');
                SIDECONTENT.lockBody();
            }
        }

        window.onpopstate = function(event) {
            var str = document.location + '';
            _activeContent = _doc.querySelectorAll('.m-side-content.is-active');
            Array.prototype.forEach.call(_activeContent, function(el, i){
                el.classList.remove('is-active');
            });
            if (_sideContentIds.indexOf(window.location.hash.substring(1)) != -1) {

                var _id = window.location.hash;
                _doc.querySelector(_id).classList.add('is-active');
                SIDECONTENT.lockBody();

            } else {
                SIDECONTENT.close();
            }
        };
    }

    return {
        init: _init
    }

}

export default Services
