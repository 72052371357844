import SideContent from './_sideContent.js';

require('../../../../node_modules/gsap/ScrollToPlugin.js');

const SIDECONTENT = new SideContent;

const News = function() {

    var _doc = document;
    var _body = _doc.querySelector('body');
    var _triggers = _doc.querySelectorAll('[data-pos-news]');
    var _closeTrigger = _doc.querySelector('.m-close-content');
    var _triggerAll = _doc.querySelector('[data-all-news]');
    var _sectionAll = _doc.querySelector('.m-side-content--all-news');
    var _allNews = _doc.querySelectorAll('.m-list-all__news');

    function _search(nameKey, myArray){
        for (var i=0; i < myArray.length; i++) {
            if (myArray[i].pos === nameKey) {
                return myArray[i];
            }
        }
    }

    function _listenerNewsContent() {
        Array.prototype.forEach.call(_triggers, function(el, i){

            el.addEventListener('click', function(e) {

                e.preventDefault();

                var _resultObject = _search(parseInt(el.getAttribute('data-pos-news')), window.list_news);
                var _newsSideContent = _doc.querySelector('.m-side-content--news');
                var _newsFooter = _newsSideContent.querySelector('.m-side-content__footer');

                TweenLite.to(_newsSideContent.querySelector('.m-side-content__scroll'), .1, {
                    scrollTo: {y:0}
                });

                SIDECONTENT.lockBody();

                _newsSideContent.classList.add('is-active');
                _newsSideContent.classList.add('is-loading');

                // RENDER NEWS
                setTimeout(() => {
                    _newsSideContent.querySelector('.m-side-content__title span').innerHTML = _resultObject.title;
                    _newsSideContent.querySelector('.m-news-container').innerHTML = _resultObject.content;
                    _newsSideContent.querySelector('.f-date').innerHTML = _resultObject.date;
                    if (_resultObject.prev === false) {
                        _newsFooter.querySelector('[data-prev-news]').classList.add('is-disabled');
                    } else {
                        _newsFooter.querySelector('[data-prev-news]').classList.remove('is-disabled');
                        _newsFooter.querySelector('[data-prev-news]').setAttribute('data-pos-news', _resultObject.prev);
                    }
                    if (_resultObject.next === false) {
                        _newsFooter.querySelector('[data-next-news]').classList.add('is-disabled');
                    } else {
                        _newsFooter.querySelector('[data-next-news]').classList.remove('is-disabled');
                        _newsFooter.querySelector('[data-next-news]').setAttribute('data-pos-news', _resultObject.next);
                    }
                    _newsSideContent.classList.remove('is-loading');
                }, 300);

            });
        });

        _triggerAll.addEventListener('click', function(e) {
            e.preventDefault();
            SIDECONTENT.lockBody();
            _sectionAll.classList.add('is-active');
        });
    }

    function _listenerSelect() {
        var _selectWrapper = _doc.querySelector('.m-custom-select-news');
        var _select = _selectWrapper.querySelector('select');

        _select.onchange = function() {
            var index = this.selectedIndex;
            var inputText = this.children[index].innerHTML.trim();
            var value = this.children[index].value;
            _sectionAll.classList.add('is-loading');
            _selectWrapper.querySelector('span').innerHTML = inputText;
            setTimeout(()=> {
                _sectionAll.classList.remove('is-loading');
            }, 300)
            setTimeout(()=> {
                _filter(value);
            }, 350)
        }
    }

    function _filter(value) {
        if (value == 'all') {
            Array.prototype.forEach.call(_allNews, function(el, i){
                el.classList.remove('is-hidden');
            });
        } else {
            Array.prototype.forEach.call(_allNews, function(el, i){
                var elAttribute = el.getAttribute('data-year');
                if (elAttribute != value) {
                    el.classList.add('is-hidden');
                } else {
                    el.classList.remove('is-hidden');
                }
            });
        }
    }

    function _init() {
        _listenerNewsContent();
        _listenerSelect();
    }

    return {
        init: _init
    }

}

export default News

